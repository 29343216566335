import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "../../store/appContext";
import axios from "axios";
import { useDispatchHelpers } from "../../store/dispatchhelper";
import Banner from "../../components/banner/banner";
import WebLoader from "../../components/webLoader/WebLoader";
import "./immobilienPrivate.scss";
import ServiceDescription from "../../components/serviceDescription/serviceDescription";

const ImmobilienPrivate = () => {
  const {
    state: { baseUrl, immobielienPrivate, lang },
  } = useContext(AppContext);
  const { dispatchAction } = useDispatchHelpers();
  const isDataReady = Object.keys(immobielienPrivate).length > 0;
  const [cardOpenedData, setCardOpenedData] = useState(false);

  useEffect(() => {
    axios.get(`${baseUrl}immobielien-private`).then((response) => {
      dispatchAction("setImmobielienPrivateData", response?.data?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderImmobielienList = () => {
    return (
      <div className="page-container" id="page-container">
        <h2
          className="title"
          dangerouslySetInnerHTML={{
            __html:
              immobielienPrivate.immobielien_private.header["title_" + lang],
          }}
        />
        <div className="immobielien-private-body">
          {immobielienPrivate.immobielien_private.data.map((item, i) => {
            return (
              <div key={`immobielien-private-card-${i}`} className="card" onClick={()=>setCardOpenedData(item)}>
                <img
                  src={item.carosel_images[0]}
                  alt="immobielien-private-image"
                />
                <div className="card-content">
                  <h3
                    className="card-title"
                    dangerouslySetInnerHTML={{
                      __html: item["title_" + lang],
                    }}
                  />
                  <p
                    className="card-desc"
                    dangerouslySetInnerHTML={{
                      __html: item["description_" + lang],
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <WebLoader isLoading={!isDataReady} />
      {immobielienPrivate && isDataReady && (
        <div className="immobielien-private-page page">
          {
            <Banner
              mediaUrl={immobielienPrivate.banners[0].path}
              title={immobielienPrivate.banners[0]["title_" + lang]}
            />
          }
          {!cardOpenedData? renderImmobielienList(): <ServiceDescription data={cardOpenedData} close={()=>{setCardOpenedData(false)}} lang={lang}/>}
        </div>
      )}
    </React.Fragment>
  );
};

export default ImmobilienPrivate;
