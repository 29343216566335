import React,{useEffect,useContext} from 'react';
import "./versicherungPrivate.scss";
import { AppContext } from '../../store/appContext';
import axios from "axios";
import { useDispatchHelpers } from "../../store/dispatchhelper";
import VersicherungCard from '../../components/versicherungCard/versicherungCard';
import Banner from '../../components/banner/banner';
import WebLoader from '../../components/webLoader/WebLoader';

const VersicherungPrivate = () => {
  const {
    state: { baseUrl, versicherungPrivate, lang },
  } = useContext(AppContext);
  const { dispatchAction } = useDispatchHelpers();
  const isDataReady = Object.keys(versicherungPrivate).length > 0;

  useEffect(() => {
    axios.get(`${baseUrl}versicherung-private`).then((response) => {
      dispatchAction("setVersicherungPrivateData", response?.data?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <React.Fragment>
    <WebLoader isLoading={!isDataReady} />
      {versicherungPrivate && isDataReady && (
        <section className="versicherung-private page">
        {<Banner mediaUrl={versicherungPrivate.banners[0].path} title={versicherungPrivate.banners[0]["title_"+lang]}/>}
        <div className="page-container" id='page-container'>
          <h2 className="title" dangerouslySetInnerHTML={{ __html: versicherungPrivate.versicherung_private.header["title_"+lang] }} />
          {versicherungPrivate.versicherung_private.data.map((item, i)=>{
            return <VersicherungCard title={item["title_"+lang]} desc={item["description_"+lang]} key={`public-card-${i}`}/>
          })}
        </div>
      </section>
    )}
    </React.Fragment>
  )
}

export default VersicherungPrivate;