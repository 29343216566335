import React, { useState } from "react";
import leftArrow from "../../assets/images/left-arrow.svg";
import "./serviceDescription.scss";

const ServiceDescription = ({ data, close, lang }) => {
  const { carosel_images } = data;
  const [imagePreview, setImagePreview] = useState(carosel_images[0]);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleImageClick = () => {
    setIsFullScreen(true);
  };

  const handleFullScreenClose = () => {
    setIsFullScreen(false);
  };

  return (
    <div className="page page-container service-desc-page">
      {isFullScreen && (
        <div className="full-screen-image" onClick={handleFullScreenClose}>
          <img src={imagePreview} alt="full-screen-image" />
        </div>
      )}
      <div className="close-btn" onClick={close}>
        <img src={leftArrow} alt="back-arrow" />
      </div>
      <div className="service-desc-page-content">
        <div className="images-wrapper">
          <div className="image-preview">
            <img
              src={imagePreview}
              alt="image-preview"
              onClick={handleImageClick}
            />
          </div>
          <div className="carousel-container">
            {carosel_images.map((image, i) => {
              return (
                <div
                  key={`image-${i}`}
                  className="carousel-item"
                  onClick={() => setImagePreview(image)}
                >
                  <img src={image} alt="carousel-image-item" />
                </div>
              );
            })}
          </div>
        </div>
        <div className="service-details">
          <h2
            className="card-title"
            dangerouslySetInnerHTML={{
              __html: data["title_" + lang],
            }}
          />
          <p
            className="card-desc"
            dangerouslySetInnerHTML={{
              __html: data["description_" + lang],
            }}
          />
          {data.file && <a className="download-btn" href={data.pdf_url} download>
            Download PDF
          </a>}
        </div>
      </div>
    </div>
  );
};

export default ServiceDescription;
