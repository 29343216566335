import React,{useEffect,useContext} from 'react';
import { AppContext } from '../../store/appContext';
import axios from "axios";
import { useDispatchHelpers } from "../../store/dispatchhelper";
import Banner from '../../components/banner/banner';
import WebLoader from '../../components/webLoader/WebLoader';
import './uber.scss';

const Uber = () => {
  const {
    state: { baseUrl, uber, lang },
  } = useContext(AppContext);
  const { dispatchAction } = useDispatchHelpers();
  const isDataReady = Object.keys(uber).length > 0;

  useEffect(() => {
    axios.get(`${baseUrl}edelmetalle-uber`).then((response) => {
      dispatchAction("setUberData", response?.data?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <React.Fragment>
      <WebLoader isLoading={!isDataReady} />
      {uber && isDataReady && <div className="uber-page page">
        {<Banner mediaUrl={uber.banners[0].path} title={uber.banners[0]["title_"+lang]}/>}
        <div className="page-container" id='page-container'>
          <h2 className="title" dangerouslySetInnerHTML={{ __html: uber.edelmetalle_uber.data[0]["title_"+lang] }} />
          <img src={uber.edelmetalle_uber.data[0].image} alt="uber-image"/>
          <p className="desc" dangerouslySetInnerHTML={{ __html: uber.edelmetalle_uber.data[0]["description_"+lang] }} />
          <div className='three-cards'>
            {uber.edelmetalle_uber.interest_rates.map((item, i)=>{
              return <div key={`uber-card-${i}`} className="card">
                <h3 className="card-title" dangerouslySetInnerHTML={{ __html: item["interest_rate_title_"+lang]}} />
                <p className="card-desc" dangerouslySetInnerHTML={{ __html: item["interest_rate_description_"+lang]}} />
              </div>
            })}
          </div>
          <p className="desc" dangerouslySetInnerHTML={{ __html: uber.edelmetalle_uber.data[1]["description_"+lang] }} />
          <div className='four-cards'>
            {uber.edelmetalle_uber.metals.map((item, i)=>{
              return <div key={`uber-card-${i}`} className="card">
                <h3 className="card-title" dangerouslySetInnerHTML={{ __html: item["metals_title_"+lang]}} />
                <p className="card-desc" dangerouslySetInnerHTML={{ __html: item["metals_description_"+lang]}} />
              </div>
            })}
          </div>
        </div>
      </div>}
    </React.Fragment>
  );
}

export default Uber;