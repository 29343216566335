import React,{useEffect,useContext} from 'react';
import { AppContext } from '../../store/appContext';
import axios from "axios";
import { useDispatchHelpers } from "../../store/dispatchhelper";
import Banner from '../../components/banner/banner';
import WebLoader from '../../components/webLoader/WebLoader';
import './cashBack.scss';

const CashBack = () => {

  const {
    state: { baseUrl, cashBack, lang },
  } = useContext(AppContext);
  const { dispatchAction } = useDispatchHelpers();
  const isDataReady = Object.keys(cashBack).length > 0;

  useEffect(() => {
    axios.get(`${baseUrl}cash-back`).then((response) => {
      dispatchAction("setCashBackData", response?.data?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <React.Fragment>
      <WebLoader isLoading={!isDataReady} />
      {cashBack && isDataReady && (
        <div className="cash-back-page page">
          <Banner
            mediaUrl={cashBack.banners[0].path}
            title={cashBack.banners[0]["title_" + lang]}
          />
          <div className="page-container" id="page-container">
            <div className="cash-back-body">
              {cashBack.cash_back.map((item, i) => {
                const images = item.carosel_images;
                return (
                  <div key={`cash-back-card-${i}`} className="card">
                    <img src={images} alt="cash-back-image" />
                    <div className='card-content'>
                      <h3
                        className="card-title"
                        dangerouslySetInnerHTML={{
                          __html: item["title_" + lang],
                        }}
                      />
                      <p
                        className="card-desc"
                        dangerouslySetInnerHTML={{
                          __html: item["description_" + lang],
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default CashBack;