import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./homeCard.scss";
const HomeCard = ({ slug, text, index, position, description }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);

  const fadeInOutTooltip = (show) => {
    if (show) {
      setShowTooltip(show);
      setTimeout(() => setFadeIn(true), 100);
      return;
    }
    setFadeIn(show);
    setShowTooltip(show);
  };
  return (
    <div
      className="home-card"
      onMouseEnter={() => fadeInOutTooltip(true)}
      onMouseLeave={() => fadeInOutTooltip(false)}
    >
      <Link
        to={`/${slug}`}
        className={`home-card-container home-card-${position}`}
      >
        {showTooltip && (
          <span
            className={`home-card-tooltip ${fadeIn && "fade-in"}`}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
        <span
          className="home-card-text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <span className="home-card-index">{index}</span>
      </Link>
    </div>
  );
};

export default HomeCard;
