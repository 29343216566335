import { useContext, useEffect } from "react";
import { AppContext } from "../../store/appContext";
import axios from "axios";
import { useDispatchHelpers } from "../../store/dispatchhelper";
import { Link } from "react-router-dom";
import translate from "./../../services/translation.json";
import Logo from "./../../assets/images/logo-white.svg";
import mailIcon from "./../../assets/images/email.png";
import phoneIcon from "./../../assets/images/tel.png";
import locationIcon from "./../../assets/images/loc.png";
import linkedIn from "./../../assets/images/linkedin.png";
import facebook from "./../../assets/images/fb.png";
import instagram from "./../../assets/images/instagram.png";
import "./footer.scss";
const Footer = () => {
  const {
    state: { baseUrl, lang, footer },
  } = useContext(AppContext);
  const { dispatchAction } = useDispatchHelpers();
  
  useEffect(() => {
    axios.get(`${baseUrl}general-information`).then((response) => {
      dispatchAction("setFooterData", response?.data?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <footer className="footer">
      <div className="footer-container footer-top">
        <div className="footer-logo">
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </div>
        <div className="footer-lists">
          <div className="footer-list">
            <div className="footer-list-title">{translate[lang].contactus}</div>
            <ul className="footer-list-content">
              <li>
                <img src={phoneIcon} alt="phone number" />
                <span className="footer-phonenumbers">
                  {Object.keys(footer).length &&
                    footer.phone_numbers.map((phone_number) => (
                      <a
                        key={`footer-phone-nbs-${phone_number}`}
                        rel="noreferrer"
                        href={`tel:${phone_number}`}
                        target="_blank"
                      >
                        {phone_number}
                      </a>
                    ))}
                </span>
              </li>
              <li>
                <img src={mailIcon} alt="email" />
                <a
                  rel="noreferrer"
                  href={`mailto:${footer.email}`}
                  target="_blank"
                >
                  {footer.email}
                </a>
              </li>
              <li>
                <img src={locationIcon} alt="address" />
                <span>{footer.address}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-container">
          <div
            className="footer-rights"
            dangerouslySetInnerHTML={{ __html: footer[`privacy_${lang}`] }}
          />
          <div className="footer-media">
            <a rel="noreferrer" href={footer.linkedIn} target="_blank">
              <img src={linkedIn} alt="linkedin link" />
            </a>
            <a rel="noreferrer" href={footer.facebook} target="_blank">
              <img src={facebook} alt="facebook link" />
            </a>
            <a rel="noreferrer" href={footer.instagram} target="_blank">
              <img src={instagram} alt="instagram link" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
