import React, { useContext, useEffect, useState, createRef } from "react";
import axios from "axios";
import { AppContext } from "../../store/appContext";
import { useDispatchHelpers } from "../../store/dispatchhelper";
import HomeCard from "../../components/homeCard/homeCard";
import "./homepage.scss";
import LogoBg from "./../../assets/images/home-page-bg.png";
import translate from "./../../services/translation.json";
import WebLoader from "./../../components/webLoader/WebLoader";
const Homepage = () => {
  const {
    state: { baseUrl, home, lang },
  } = useContext(AppContext);
  const { dispatchAction } = useDispatchHelpers();

  const [tutorialMode, setTutorialMode] = useState(
    localStorage.getItem("tutorial_done") === "true" ? false : true
  );
  const [tutorialText, setTutorialText] = useState("");
  const isDataReady = Object.keys(home).length > 0;
  const logoImgRef = createRef();
  const homeCardRef = createRef();

  const tutorialSteps = [
    translate[lang].welcome_to_spfg,
    translate[lang].mutli_services,
    translate[lang].press_option,
    "",
  ];

  const tutorialStepTime = 3000;

  useEffect(() => {
    axios.get(`${baseUrl}home`).then((response) => {
      dispatchAction("setHomeData", response?.data?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // inialize the tutorial
    if (tutorialMode && home.length > 0) {
      const imgElm = logoImgRef.current;
      const homeCardElm = homeCardRef.current;
      tutorialSteps.forEach((step, index) => {
        setTimeout(() => {
          if (index === 0) {
            imgElm.style.zIndex = "2";
          }
          if (index === 2) {
            imgElm.style.zIndex = "";
            homeCardElm.style.zIndex = "2";
          }
          setTutorialText(step);
          if (index === 3) {
            homeCardElm.style.zIndex = "";
            setTutorialMode(false);
            localStorage.setItem("tutorial_done", "true");
          }
        }, index * tutorialStepTime);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [home]);

  const renderFirstRow = () => {
    return (
      <div className="row-sm">
        <div ref={homeCardRef}>
          <HomeCard
            text={home[0]["title_" + lang]}
            index={1}
            position={"right"}
            slug={home[0].slug}
            description={home[0]["description_" + lang]}
          />
        </div>
        <HomeCard
          text={home[7]["title_" + lang]}
          index={8}
          position={"left"}
          slug={home[7].redirect_slug}
          description={home[7]["description_" + lang]}
        />
      </div>
    );
  };

  const renderLastRow = () => {
    return (
      <div className="row-sm">
        <HomeCard
          text={home[3]["title_" + lang]}
          index={4}
          position={"right"}
          slug={home[3].redirect_slug}
          description={home[3]["description_" + lang]}
        />
        <HomeCard
          text={home[4]["title_" + lang]}
          index={5}
          position={"left"}
          slug={home[4].redirect_slug}
          description={home[4]["description_" + lang]}
        />
      </div>
    );
  };

  const renderMiddleRow = () => {
    return (
      <div className="row-md">
        <div className="col-sm">
          <HomeCard
            text={home[1]["title_" + lang]}
            index={2}
            position={"right"}
            slug={home[1].redirect_slug}
            description={home[1]["description_" + lang]}
          />
          <HomeCard
            text={home[2]["title_" + lang]}
            index={3}
            position={"right"}
            slug={home[2].redirect_slug}
            description={home[2]["description_" + lang]}
          />
        </div>
        <img ref={logoImgRef} src={LogoBg} alt="logo" />
        <div className="col-sm">
          <HomeCard
            text={home[6]["title_" + lang]}
            index={7}
            position={"left"}
            slug={home[6].redirect_slug}
            description={home[6]["description_" + lang]}
          />
          <HomeCard
            text={home[5]["title_" + lang]}
            index={6}
            position={"left"}
            slug={home[5].redirect_slug}
            description={home[5]["description_" + lang]}
          />
        </div>
      </div>
    );
  };

  const renderTutorial = () => {
    return (
      <>
        <div className="tutorial-text">{tutorialText}</div>
        <div className="tutorial-layer"></div>
      </>
    );
  };

  const renderMobile = () => {
    return (
      <div className="mobile">
        <img src={LogoBg} alt="logo" />
        {home.map((item, index) => (
          <HomeCard
            text={item["title_" + lang]}
            index={index + 1}
            key={"item-"+index}
            position={"left"}
            slug={item.redirect_slug}
            description={item["description_" + lang]}
          />
        ))}
      </div>
    );
  };

  return (
    <React.Fragment>
      <WebLoader isLoading={!isDataReady} />
      {home && isDataReady && (
        <div className="home-page page">
          <div className="desktop">
            {renderFirstRow()}
            {renderMiddleRow()}
            {renderLastRow()}
            {tutorialMode && renderTutorial()}
          </div>
          {renderMobile()}
        </div>
      )}
    </React.Fragment>
  );
};

export default Homepage;
