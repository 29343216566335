import React from "react";
import { ReactComponent as ArrowDownTag } from "../../assets/images/arrow-down-banner.svg";
import "./banner.scss";
function Banner({ mediaUrl, title }) {
  return (
    <div
      className="banner-wrapper banner-wrapper-image"
      style={{ backgroundImage: `url("${mediaUrl}")` }}
    >
      <div
        className="banner-title"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <div className="banner-scroll">
        <a href="#page-container">
          <ArrowDownTag className="banner-scroll-arr" />
        </a>
      </div>
    </div>
  )
}

export default Banner;
