import React,{useContext} from 'react'
import { AppContext } from '../../store/appContext';
import bannerImg from './../../assets/images/metamorh-banner.png';
import lifeCycleImg from './../../assets/images/metamorh-body.png';
import Banner from './../../components/banner/banner';
import translate from './../../services/translation.json';
import './IT-Losungen.scss';

const ITLosungen = () => {
  const {
    state: { lang },
  } = useContext(AppContext);

  const servicesData = [
    {
      title:translate[lang].metamorph.services.web_app_title,
      desc:translate[lang].metamorph.services.web_app_desc
    },{
      title:translate[lang].metamorph.services.mobile_app_title,
      desc:translate[lang].metamorph.services.mobile_app_desc
    },{
      title:translate[lang].metamorph.services.cloud_title,
      desc:translate[lang].metamorph.services.cloud_desc
    },{
      title:translate[lang].metamorph.services.ui_title,
      desc:translate[lang].metamorph.services.ui_desc
    }
  ];

  return (
    <div className='it-losungen page'>
      <Banner mediaUrl={bannerImg} title={translate[lang].metamorph.banner_title}/>
      <div className="it-losungen-body" id='page-container'>
        <div className="it-losungen-body-content">
          <h2>{translate[lang].metamorph.services.title}</h2>
          <div className="it-losungen-body-content-services">
            {servicesData.map((service, index) => (
              <div key={index} className="it-losungen-body-content-services-item">
                <h3>{service.title}</h3>
                <p>{service.desc}</p>
              </div>
            ))}
          </div>
        </div>
        <div className='it-losungen-body-img'>
          <img src={lifeCycleImg} alt="life cycle"/>
        </div>
      </div>
    </div>
  )
}

export default ITLosungen;