import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../store/appContext";
import "./renovationDetails.scss";
import axios from "axios";
import { useDispatchHelpers } from "../../store/dispatchhelper";
import ServiceDescription from "../../components/serviceDescription/serviceDescription";
import Banner from "../../components/banner/banner";
import WebLoader from "../../components/webLoader/WebLoader";
const RenovationDetails = () => {
  const {
    state: { baseUrl, renovation, lang },
  } = useContext(AppContext);
  const { dispatchAction } = useDispatchHelpers();
  const [selectedData, setSelectedData] = useState({});
  const { id } = useParams();
  const isDataReady = Object.keys(selectedData).length > 0;
  const [cardOpenedData, setCardOpenedData] = useState(false);

  useEffect(() => {
    axios.get(`${baseUrl}immobielien-rennovation`).then((response) => {
      dispatchAction("setRenovationData", response?.data?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (renovation && Object.keys(renovation).length > 0) {
      const data =
        renovation.immobielien_rennovation.data.rennovation_details_data;
      const selected = data.filter((item) => item.id === parseInt(id));
      setSelectedData(selected[0]);
    }
  }, [renovation,id]);

  const renderRenovationDetails = () => {
    return (
      <div className="page-container" id="page-container">
        <h2
          className="title"
          dangerouslySetInnerHTML={{
            __html: selectedData.rennovation_details_header["title_" + lang],
          }}
        />
        <div className="renovation-details-page" id="page-container">
          {selectedData && isDataReady && (
            <div className="renovation-details-body">
              {selectedData.rennovation_details.map((item, i) => {
                const images = item.carosel_images;
                return (
                  <div
                    key={`renovation-details-card-${i}`}
                    className="card"
                    onClick={() => setCardOpenedData(item)}
                  >
                    <img src={images[0]} alt="renovation-details-image" />
                    <div className="card-content">
                      <h3
                        className="card-title"
                        dangerouslySetInnerHTML={{
                          __html: item["title_" + lang],
                        }}
                      />
                      <p
                        className="card-desc"
                        dangerouslySetInnerHTML={{
                          __html: item["description_" + lang],
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <WebLoader isLoading={!isDataReady} />
      {selectedData && isDataReady && (
        <div className="renovation-details-page page" id="page-container">
          <Banner
            mediaUrl={renovation.banners[0].path}
            title={renovation.banners[0]["title_" + lang]}
          />
          {!cardOpenedData? renderRenovationDetails():
          <ServiceDescription
            data={cardOpenedData}
            close={() => {
              setCardOpenedData(false);
            }}
            lang={lang}
          />}
        </div>
      )}
    </React.Fragment>
  );
};

export default RenovationDetails;
