import React,{useEffect,useContext} from 'react';
import { AppContext } from '../../store/appContext';
import "./edelmetalle.scss";
import axios from "axios";
import { useDispatchHelpers } from "../../store/dispatchhelper";
import Banner from '../../components/banner/banner';
import WebLoader from '../../components/webLoader/WebLoader';
const Edelmetalle = () => {

  const {
    state: { baseUrl, edelmetalle, lang },
  } = useContext(AppContext);
  const { dispatchAction } = useDispatchHelpers();
  const isDataReady = Object.keys(edelmetalle).length > 0;

  useEffect(() => {
    axios.get(`${baseUrl}edelmetalle`).then((response) => {
      dispatchAction("setEdelmetalleData", response?.data?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <React.Fragment>
      <WebLoader isLoading={!isDataReady} />
      {edelmetalle && isDataReady && <div className="edelmetalle-page page">
        {<Banner mediaUrl={edelmetalle.banners[0].path} title={edelmetalle.banners[0]["title_"+lang]}/>}
        <div className="page-container" id='page-container'>
          <h2 className="title" dangerouslySetInnerHTML={{ __html: edelmetalle.edelmetalle[0]["title_"+lang] }} />
          <img src={edelmetalle.edelmetalle[0].image} alt="edelmetalle-image"/>
          <p className="desc" dangerouslySetInnerHTML={{ __html: edelmetalle.edelmetalle[0]["description_"+lang] }} />
        </div>
      </div>}
    </React.Fragment>
  );
}

export default Edelmetalle;