import React, { useState, useContext } from "react";
import { AppContext } from "../../store/appContext";
import { useNavigate } from 'react-router-dom';
import "./login.scss";
import translate from "./../../services/translation.json";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
const Login = ({setIsAuthenticated}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const {
    state: { lang, baseUrl },
  } = useContext(AppContext);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const renderPasswordInput = () => {
    return (
      <TextField
        id="password"
        label="Password"
        variant="filled"
        value={password}
        onChange={handlePasswordChange}
        className="mui-textfield"
        type={showPassword ? "text" : "password"}
        sx={{
          backgroundColor: "white",
          "& .MuiOutlinedInput-root": {
            backgroundColor: "white",
            "& fieldset": {
              borderColor: "transparent", // Remove border between input and icon
            },
            "&:hover fieldset": {
              borderColor: "transparent", // Remove border on hover
            },
            "&.Mui-focused fieldset": {
              borderColor: "transparent", // Remove border on focus
            },
          },
        }}
        InputLabelProps={{
          style: { color: "#C2A665" },
        }}
        InputProps={{
          endAdornment: (
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          ),
        }}
      />
    );
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post(`${baseUrl}login`, { email, password }).then((response) => {
      console.log(response.data.data);
      if (response.data.data.token === undefined) return;
      sessionStorage.setItem("token", response.data.data.token);
      setIsAuthenticated(true);
      navigate("/immobielien-private");
    });
  };

  return (
    <div className="page page-container login-page">
      <div className="form-container">
        <h2>{translate[lang].login_to_account}</h2>
        <form onSubmit={handleSubmit} className="form">
          <TextField
            id="email"
            InputLabelProps={{
              style: { color: "#C2A665" },
            }}
            sx={{
              backgroundColor: "white",
              "& .MuiOutlinedInput-root": {
                backgroundColor: "white",
                "& fieldset": {
                  borderColor: "transparent", // Remove border between input and icon
                },
                "&:hover fieldset": {
                  borderColor: "transparent", // Remove border on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent", // Remove border on focus
                },
              },
            }}
            label="Email"
            value={email}
            variant="filled"
            onChange={handleEmailChange}
            className="mui-textfield"
          />
          {renderPasswordInput()}
          <button type="submit" className="submit-btn">{translate[lang].login}</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
