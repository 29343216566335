import React, { useContext, useEffect, useState } from "react";
// import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import axios from "axios";
import { AppContext } from "../../store/appContext";
import { useDispatchHelpers } from "../../store/dispatchhelper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./header.scss";
import LangImg_en from "./../../assets/images/lang-en.png";
import LangImg_fr from "./../../assets/images/lang-fr.png";
import LangImg_de from "./../../assets/images/lang-de.png";
import LangImg_it from "./../../assets/images/lang-it.png";
import ArrowDownImg from "./../../assets/images/arrow-down.svg";
import ArrowRightImg from "./../../assets/images/right-arrow.svg";
import goldLogo from "./../../assets/images/logo-gold-header.svg";
import menuIcon from "./../../assets/images/burger-menu.png";
const Header = () => {
  const {
    state: { baseUrl, header, lang, activePage },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { dispatchAction } = useDispatchHelpers();
  const { pathname } = useLocation();
  const [openDropDown, setOpenDropDown] = useState("");
  const [catOpenDropDown, setCatOpenDropDown] = useState("");
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const [openMobileMenuClassName, setOpenMobileMenuClassName] = useState("");
  useEffect(() => {
    axios.get(`${baseUrl}menu`).then((response) => {
      dispatchAction("setHeaderData", response?.data?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const logoRender = () => {
  //   return (
  //     <DotLottieReact
  //       src="https://lottie.host/246747ef-da76-4908-a869-4f39b940bbd9/Ui6lXYlAe4.lottie"
  //       loop
  //       autoplay
  //     />
  //   );
  // }

  const getActiveHeaderItem = ({ pathname, header }) => {
    if (!header.length) return;
    const getActivePage = header?.filter((headerItem) => {
      if (pathname === "/") return headerItem.slug === "edelwealth";
      return "/" + headerItem.slug === pathname;
    });
    if (getActivePage.length) {
      dispatchAction("setActivePageData", getActivePage[0]);
      return;
    }
    for (const i in header) {
      const childrenHeaders = header[i].children;
      if (!childrenHeaders) return;
      if (getActiveHeaderItem({ pathname, header: childrenHeaders })) return;
    }
  };

  useEffect(() => {
    getActiveHeaderItem({ pathname, header });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, header]);

  //lang selection
  const getLangList = () => {
    return ["en", "fe", "de", "ita"];
  };

  const getLangImg = (language) => {
    switch (language) {
      case "en":
      default:
        return LangImg_en;
      case "fe":
        return LangImg_fr;
      case "de":
        return LangImg_de;
      case "ita":
        return LangImg_it;
    }
  };

  const handleSwitchLang = (lng) => {
    setOpenDropDown("");
    setCatOpenDropDown("");
    dispatchAction("setLang", lng);
  };

  const renderDesktopLangDropDown = () => {
    return (
      <div className="lang-selector desktop">
        <div className="header-lang ">
          <span className="header-lang-chosen">
            <img
              className="header-lang-img"
              src={getLangImg(lang)}
              alt="Language chosen"
            />
            <img
              className="header-lang-chosen-arr"
              src={ArrowDownImg}
              alt="open dropdown"
            />
          </span>
          <ul className="header-lang-list">
            {getLangList().map((lng, i) => {
              if (lng !== lang) {
                return (
                  <li
                    key={`header-lang-list-item-${i}`}
                    onClick={() => handleSwitchLang(lng)}
                  >
                    <img
                      className="header-lang-img"
                      src={getLangImg(lng)}
                      alt={`Choose Lang ${lng}`}
                    />
                  </li>
                );
              }
              return (
                <React.Fragment
                  key={`header-lang-list-item-${i}`}
                ></React.Fragment>
              );
            })}
          </ul>
        </div>
      </div>
    );
  };

  const renderDesktopNavigation = () => {
    return (
      header && (
        <nav className="header-nav desktop">
          <ul className="header-nav-list">
            {header.map((headerItem, i) => (
              <li
                key={`header-nav-list-item-${i}`}
                className="header-nav-list-item"
              >
                <Link
                  className={addActiveNavItemClassName(headerItem)}
                  to={`/${headerItem.slug}`}
                >
                  {headerItem[`title_${lang}`]}
                  {headerItem.children?.length > 0 && (
                    <img
                      className="header-nav-list-item-arr"
                      src={ArrowDownImg}
                      alt="open dropdown"
                    />
                  )}
                </Link>
                {headerItem.children?.length > 0 && (
                  <ul className="header-nav-list-item-dropdown">
                    {headerItem.children?.map((dropDownItem, i) => (
                      <li
                        key={`header-nav-list-item-dropdown-item-${i}`}
                        className="header-nav-list-item-dropdown-item"
                      >
                        <Link
                          className={addActiveNavItemClassName(dropDownItem)}
                          to={`/${dropDownItem.slug}`}
                        >
                          {dropDownItem[`title_${lang}`]}
                          {dropDownItem.subcategories?.length > 0 && (
                            <img
                              className="header-nav-list-item-arr"
                              src={ArrowRightImg}
                              alt="open dropdown"
                            />
                          )}
                        </Link>
                        {dropDownItem.subcategories?.length > 0 && (
                          <ul className="header-nav-list-item-dropdown-item-sub">
                            {dropDownItem.subcategories?.map((subItem, i) => (
                              <li
                                key={`header-nav-list-subcat-item-dropdown-item-${i}`}
                                className="header-nav-list-item-dropdown-item-sub-item"
                              >
                                <Link
                                  className={addActiveNavItemClassName(subItem)}
                                  to={`/${subItem.slug}`}
                                >
                                  {subItem[`title_${lang}`]}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </nav>
      )
    );
  };

  // --- handle navigation
  const addActiveNavItemClassName = ({ slug, children }) => {
    const isActiveMenuLink = activePage?.slug === slug;
    const isChildActive =
      children?.filter((child) => activePage.slug === child.slug).length > 0;
    return isActiveMenuLink || isChildActive ? "active" : "";
  };

  // --- handle mobile navigation
  const handleMobileNavigationOpen = (isOpen) => {
    if (isOpen) {
      setIsOpenMobileMenu(isOpen);
      setTimeout(() => {
        setOpenMobileMenuClassName("open");
      }, 50);
    } else {
      setOpenDropDown("");
      setCatOpenDropDown("");
      setOpenMobileMenuClassName("");
      setTimeout(() => {
        setIsOpenMobileMenu(false);
      }, 500);
    }
  };
  const renderMobileLangDropDown = () => {
    return (
      <div
        className={`header-mobile-nav-drawer-wrapper-content-dropdown lang ${
          openDropDown === "lang" && "open"
        }`}
      >
        <div className="menulink">
          <img src={getLangImg(lang)} alt="language selected" />
          <img
            src={ArrowDownImg}
            alt="arrow down button"
            className="open-dropdown"
            onClick={() =>
              setOpenDropDown(openDropDown === "lang" ? "" : "lang")
            }
          />
        </div>
        <div className="submenulinks">
          {getLangList().map((lng, i) => {
            if (lng !== lang) {
              return (
                <div
                  className="submenulink"
                  key={`lang-submenu-links-${i}`}
                  onClick={() => handleSwitchLang(lng)}
                >
                  <img src={getLangImg(lng)} alt={`Choose Lang ${lng}`} />
                </div>
              );
            }
            return (
              <React.Fragment key={`lang-submenu-links-${i}`}></React.Fragment>
            );
          })}
        </div>
      </div>
    );
  };
  const renderMobileNavDropDown = () => {
    return header.map((headerItem, i) => (
      <div
        key={"mobile-nav-dropdown-" + i + "-" + headerItem.slug}
        className={`header-mobile-nav-drawer-wrapper-content-dropdown nav ${
          openDropDown === headerItem.slug && "open"
        } ${addActiveNavItemClassName(headerItem)}`}
      >
        <div className={`menulink`}>
          <Link
            onClick={() => handleMobileNavigationOpen(false)}
            to={`/${headerItem.slug}`}
          >
            {headerItem[`title_${lang}`]}
          </Link>
          {headerItem.children && headerItem.children.length !== 0 && (
            <img
              src={ArrowDownImg}
              alt="arrow down button"
              className="open-dropdown"
              onClick={() =>
                setOpenDropDown(
                  openDropDown === headerItem.slug ? "" : headerItem.slug
                )
              }
            />
          )}
        </div>
        {headerItem.children && headerItem.children.length !== 0 && (
          <div className="submenulinks">
            {headerItem.children.map((dropDownItem, i) => (
              <React.Fragment key={"submenulinks-" + i}>
                <div
                  key={
                    "mobile-nav-dropdown-items-" + i + "-" + dropDownItem.slug
                  }
                  className={`submenulink ${
                    catOpenDropDown === dropDownItem.slug && "opened"
                  }`}
                >
                  <Link
                    onClick={() => handleMobileNavigationOpen(false)}
                    to={`/${dropDownItem.slug}`}
                  >
                    {dropDownItem[`title_${lang}`]}
                  </Link>
                  {dropDownItem.subcategories &&
                    dropDownItem.subcategories.length !== 0 && (
                      <img
                        src={ArrowDownImg}
                        alt="arrow down button"
                        className={`open-catg-dropdown`}
                        onClick={() =>
                          setCatOpenDropDown(
                            catOpenDropDown === dropDownItem.slug
                              ? ""
                              : dropDownItem.slug
                          )
                        }
                      />
                    )}
                </div>
                {dropDownItem.subcategories &&
                  dropDownItem.subcategories.length !== 0 && (
                    <div
                      className={`submcatlinks ${
                        catOpenDropDown === dropDownItem.slug &&
                        "open-categories"
                      }`}
                    >
                      {dropDownItem.subcategories.map((catg, i) => (
                        <div
                          key={
                            "mobile-nav-dropdown--subcat-items-" +
                            i +
                            "-" +
                            catg.slug
                          }
                          className="submcatgenulink"
                        >
                          <Link
                            onClick={() => handleMobileNavigationOpen(false)}
                            to={`/${catg.slug}`}
                          >
                            {catg[`title_${lang}`]}
                          </Link>
                        </div>
                      ))}
                    </div>
                  )}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    ));
  };
  const renderMobileNavigation = () => {
    return (
      <>
        {
          <div className="header-mobile-nav mobile">
            <img
              onClick={() => handleMobileNavigationOpen(true)}
              alt="open mobile navigation menu"
              src={menuIcon}
            />
            {isOpenMobileMenu && (
              <div
                className={`header-mobile-nav-drawer ${openMobileMenuClassName}`}
              >
                <div
                  className="header-mobile-nav-drawer-drop"
                  onClick={() => handleMobileNavigationOpen(false)}
                ></div>
                <div className="header-mobile-nav-drawer-wrapper">
                  <div className="header-mobile-nav-drawer-wrapper-content">
                    <div className="header-mobile-nav-drawer-wrapper-content-logo">
                      <Link
                        to="/"
                        onClick={() => handleMobileNavigationOpen(false)}
                      >
                        <img src={goldLogo} alt="logo" />
                      </Link>
                    </div>
                    {renderMobileLangDropDown()}
                    {renderMobileNavDropDown()}
                  </div>
                </div>
              </div>
            )}
          </div>
        }
      </>
    );
  };

  return (
    <div className="header">
      <div className="header-container">
        <div className="logo">
          <img src={goldLogo} alt="logo" onClick={()=>navigate('/')} />
        </div>
        {renderDesktopNavigation()}
        {renderDesktopLangDropDown()}

        {renderMobileNavigation()}
      </div>
    </div>
  );
};

export default Header;
