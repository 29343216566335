import React from 'react';
import './versicherungCard.scss';

const VersicherungCard = ({title, desc}) => {

  return (
    <div className="versicherung-card">
      <h2 className="versicherung-card-title" dangerouslySetInnerHTML={{ __html: title}} />
      <hr className="title-divider" />
      <p className="versicherung-card-desc" dangerouslySetInnerHTML={{ __html: desc}} />
      <hr className="desc-divider" />
      <span className="versicherung-card-gold"></span>
    </div>
  )
}

export default VersicherungCard;