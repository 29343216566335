import React from 'react';
import "./standardCard.scss";

const StandardCard = ({title, desc}) => {
  return (
    <div className="standard-card">
      <h2 className="standard-card-title" dangerouslySetInnerHTML={{ __html: title}} />
      <p className="standard-card-desc" dangerouslySetInnerHTML={{ __html: desc}} />
    </div>
  )
}

export default StandardCard;